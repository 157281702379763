:root {
  --ck-border-radius: 6px !important;
}

html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Anton', 'Public Sans', 'Outfit', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  border-radius: 0.1335rem;
}

code[class*='language-'] {
  padding: 0;
}

.optimistic-fade-out {
  --transition-speed: 250ms;
  --transition-delay: 100ms;
  opacity: 0;
  animation: optimisticFadeOut var(--transition-delay) var(--transition-speed) ease-in forwards;
}

@keyframes optimisticFadeOut {
  100% {
    display: none;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.fc-toolbar-chunk {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.fc-toolbar {
  display: flex;
  justify-content: center;
}

/* Membership.io missing classes */

.hidden {
  display: none;
}

.relative {
  position: relative;
}
